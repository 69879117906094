<template>
  <div>
    <div class="container mb-2">
      <span>
        <introduce-card
          card_id="slamdunk"
          views="5433"
          card_title="슬램덩크 닮은꼴 테스트"
          card_text="나는 천재..?"
          img_link="https://github.com/battlemango/save-image-repo/blob/74f80f85c4ec25fc3538c0b95216984b04b5272b/dimango/dunk/backho.png?raw=true"
          :link_url="url_slamdunk"
        />
      </span>
      <span>
        <introduce-card
          views="1133"
          card_id="balance"
          card_title="밸런스 게임"
          card_text="잔혹한 질문으로 친구와 수다가 터지는 게임"
          img_link="https://yhmango.com/wp-content/uploads/2022/04/logo_apple2.png"
          :link_url="url_balance"
        />
      </span>
      <span>
        <introduce-card
          views="3233"
          card_id="mbti"
          card_title="MBTI로 알아보는 우리 사이!"
          card_text="세상 편하게 MBTI 특징들 확인 가능"
          img_link="https://yhmango.com/wp-content/uploads/2022/05/mbti-resize2.png"
          :link_url="url_mbti"
        />
      </span>

      <span>
        <introduce-card
          card_id="idol"
          views="5433"
          card_title="아이돌 닮은꼴 테스트"
          card_text="한명은 닮았겠지.. 아이돌이 몇 명인데"
          img_link="https://yhmango.com/wp-content/uploads/2022/03/winter.png"
          :link_url="url_idol"
        />
      </span>
      <span>
        <introduce-card
          views="150"
          card_id="color"
          card_title="색 구분 능력 테스트"
          card_text="색깔 구분 잘함?"
          img_link="https://yhmango.com/wp-content/uploads/2022/04/color-icon.png"
          :link_url="url_color"
        />
      </span>

      <span>
        <introduce-card
          card_id="plus"
          views="512"
          card_title="덧셈 뺄셈 테스트"
          card_text="내 덧셈 실력이 얼마나 떨어졌는지 알 수 있습니다."
          img_link="https://yhmango.com/wp-content/uploads/2022/03/plusicon2.png"
          :link_url="url_plusminus"
        />
      </span>

      <span>
        <introduce-card
          views="233"
          card_id="brick"
          card_title="블록게임 게임"
          card_text="스트레스 풀리는 블록 부수기 게임. 어쩌면 쌓일 수도 있습니다."
          img_link="https://yhmango.com/wp-content/uploads/2022/03/brick_icon.png"
          :link_url="url_brick"
        />
      </span>
    </div>
    <adsense-auto />
  </div>
</template>

<script>
import IntroduceCard from "./IntroduceCard.vue";
import AdsenseAuto from "./../../components/adsense/AdsenseAuto.vue";

export default {
  name: "GridView",
  components: {
    IntroduceCard,
    AdsenseAuto,
  },
  data: function () {
    return {
      url_idol: window.location.origin + "/idol",
      url_slamdunk: window.location.origin + "/slamdunk",
      url_plusminus: window.location.origin + "/plusminus",
      url_brick: window.location.origin + "/brick",
      url_color: window.location.origin + "/color",

      url_balance: window.location.origin + "/balance",
      url_mbti: window.location.origin + "/mbti",
    };
  },
};
</script>

<style scoped>
.container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 350px);
  gap: 7px 7px;
}
</style>
