<template>
  <div>
    <my-appbar main_tag="#디망고 #닮은꼴 찾기 #소소한 힐링" />

    <grid-view />
  </div>
</template>

<script>
import MyAppbar from "./../../components/MyAppbar.vue";
import GridView from "./GridView.vue";
import NetworkUtils from "./../../common/NetworkUtils.js";
export default {
  name: "MainPage",
  components: {
    MyAppbar,
    GridView,
  },
  mounted() {
    NetworkUtils.countVisitors("home", "visit");
  },
};
</script>
