<template>
  <div class="card mx-auto" :id="card_id" @click="cardClicked" :background="aa">
    <div class="additional">
      <div class="user-card">
        <!-- <div class="my-points">{{ views }} Views</div> -->
        <div>
          <img class="center img-circle ml-2" :src="img_link" />
        </div>
      </div>
    </div>
    <div class="general">
      <div class="my-align-center card-title mt-3">{{ card_title }}</div>
      <div class="card-contents pl-1 mt-1">
        {{ card_text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img_link: String,
    card_text: String,
    card_title: String,
    link_url: String,
    card_id: String,
    views: String,
  },

  data: function () {
    return {
      aa: "bb",
    };
  },
  mounted() {
    let element = document.getElementById(this.card_id);
    switch (this.card_id) {
      case "slamdunk":
        break;

      case "idol":
        element.classList.add("green");
        break;
      case "plus":
        element.classList.add("green");
        break;
      case "brick":
        element.classList.add("yellow");
        break;
      case "balance":
        element.classList.add("balance");
        break;
      case "color":
        element.classList.add("color");
        break;
      case "mbti":
        element.classList.add("mbti");
        break;
    }
  },

  methods: {
    cardClicked() {
      window.location.href = this.link_url;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Nanum+Gothic:wght@400;700&display=swap");
/* font-family: 'Black Han Sans', sans-serif;
font-family: 'Nanum Gothic', sans-serif; */
.card-title {
  font-size: 0.9rem;
  font-family: "Sunflower", sans-serif;
  font-weight: 500;
  color: #0a4658;
}
.card-contents {
  font-size: 0.8rem;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  color: #353535;
}

.img-circle {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.center {
  position: absolute;
  top: 12%;
  left: 3%;
}

.card {
  max-width: 335px;

  min-width: 335px;
  height: 88px;
  background-color: #fff;
  background: linear-gradient(#f8f8f8, #fff);
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.card:hover {
  z-index: 3;
  -webkit-transition: all 150ms ease-in;
  -webkit-transform: scale(1.1);
  -ms-transition: all 150ms ease-in;
  -ms-transform: scale(1.2);
  -moz-transition: all 150ms ease-in;
  -moz-transform: scale(1.1);
  transition: all 150ms ease-in;
  transform: scale(1.1);
}

.my-align-center {
  text-align: center;
}

.card .additional {
  position: absolute;
  width: 90px;
  height: 100%;
  background: linear-gradient(#de685e, #ee786e);
  transition: width 0.4s;
  overflow: hidden;
  z-index: 2;
}

.card.green .additional {
  background: linear-gradient(#92bca6, #a2ccb6);
}
.card.yellow .additional {
  background: linear-gradient(#f3dc5b, #f8e15f);
}

.card.balance .additional {
  background: linear-gradient(#67c2dd, #579baf);
}
.card.color .additional {
  background: linear-gradient(rgb(243, 181, 170), rgb(241, 199, 192));
}
.card.mbti .additional {
  background: linear-gradient(rgb(228, 183, 253), rgb(164, 135, 170));
}

.card .additional .user-card {
  width: 90px;
  height: 100%;
  position: relative;
  float: left;
}

.my-points {
  z-index: 5;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.4em;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.card .additional .user-card .points {
  top: 75%;
}

.card .general {
  width: 235px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  box-sizing: border-box;
  padding: 0.3rem;
  padding-top: 0;
}
</style>
