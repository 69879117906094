<template>
  <div>
    <v-app-bar app absolute color="white" dense elevation="0" height="33">
      <div id="site-logo-inner" class="clr">
        <img
          src="https://yhmango.com/wp-content/uploads/2022/03/android-icon-36x36-1.png"
          class="custom-logo"
          alt="dmango"
          srcset=""
          @click="logoClicked()"
        />
      </div>
      <v-toolbar-title>
        <div class="bar_title" @click="logoClicked()">Dimango</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    </v-app-bar>
    <h1 class="my_h1 mt-8 ml-6">{{ main_tag }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    main_tag: String,
  },
  mounted() {
    //console.log(this.title);
  },
  methods: {
    logoClicked() {
      window.location.href = window.location.origin;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://fonts.googleapis.com/css?family=Proza+Libre");

.bar_title {
  font-size: 0.95rem;
  font-family: "Proza Libre", sans-serif;
  color: #353535;
}

.my_h1 {
  font-size: 0.9rem;
  font-family: "Proza Libre", sans-serif;
  font-weight: normal;
  color: #dd4621;
}

.my_subtitle {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
.custom-logo {
  height: 21px;
}

@media (max-width: 600px) {
  .my_h1 {
    font-size: 0.8rem;
  }
}
</style>
