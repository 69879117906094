<template>
  <div>
    <main-page class="main-page" />
    <common-footer :needDownloadIcon="true" />
  </div>
</template>

<script>
import MainPage from "./../views/MainPage.vue";
import CommonFooter from "./../../components/CommonFooter.vue";

export default {
  name: "Home",

  components: {
    MainPage,
    CommonFooter,
  },
};
</script>

<style scoped>
.main-page {
  padding-bottom: 90px;
}
</style>
